// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('.search-btn').on('click', function () {
    $('.search-main').toggle('active');
    // $('.search__input').focus();
  });
  /**
   * moove characters image on load
   */
  setTimeout(() => {
    $('.bird-n-cat').addClass('moove-characters');
    $('.gear-img').addClass('moove-gear');
  }, 1500);

  $('.skip-lazy img').attr('data-no-lazy', '1');
  $('.skip-lazy img').addClass('skip-lazy');

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  $('.footer-category-list__item').matchHeight();
  // $('.articles-list .post-title').matchHeight();
  // $('.articles-list-card__category').matchHeight();

  /**
   * Hover effect on posts
   */

  $('.js-hover').each(function () {
    let elem = $(this);
    elem.find('.js-element-hover').on('mouseenter', function () {
      elem.find('.js-element-hover').addClass('js-active');
    });
    elem.find('.js-element-hover').on('mouseleave', function () {
      elem.find('.js-element-hover').removeClass('js-active');
    });
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 500 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      document.body.classList.toggle('lock-scroll');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  // Get the modal
  $('.modalBtn').on('click', function () {
    $('.modal-window').removeClass('hide');
    $('html').addClass('noscroll');
  });

  $('.close').on('click', function () {
    $('.modal-window').addClass('hide');
    $('html').removeClass('noscroll');
  });
  // End of Get the modal

  //Read more button js
  $('.read-more-btn').on('click', function () {
    $(this).parents('.wpb_wrapper').find('.read-more-box').toggleClass('hide');
    $(this)
      .parents('.wpb_wrapper')
      .find('.read-more-container')
      .toggleClass('open');
    $(this)
      .parents('.wpb_wrapper')
      .find('.read-more-btn')
      .text('Continue Reading');
    $(this)
      .parents('.wpb_wrapper')
      .find('.open .read-more-btn')
      .text('Read Less');
  });
  //End of Read more button js
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  console.log(navigator.platform);
  // jQuery code goes here
  if (
    navigator.platform.indexOf('Mac') === 0 ||
    navigator.platform === 'iPhone'
  ) {
    $('.cat-link__text').addClass('mac-os');
    // $('.cat-title-font').addClass('mac-os-font');
    // console.log('dhswgfhwbg');
  }

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

const allAnimatedSections = document.querySelectorAll('.image-with-text');
allAnimatedSections.forEach(function (activeSection) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        window.addEventListener('scroll', function () {
          navHighlighter(entry.target);
        });
      }
    });
  });
  observer.observe(activeSection);
});

function navHighlighter(current) {
  // console.log(current);
  let currentElem = current.querySelector('.scroll-row');
  if (
    current.getBoundingClientRect().top < 400 &&
    current.getBoundingClientRect().bottom > 0
  ) {
    currentElem.classList.add('animated-block');
    current.classList.add('is-visiable');
  } else {
    currentElem.classList.remove('animated-block');
    current.classList.remove('is-visiable');
  }
}
